<template>
  <div class="inter-wrapper">
    <HeaderInter back-path="/user" />
    <div class="inter-content container-fluid">
      <div class="main pb-5">
        <dl>
          <dt>關於我們 </dt>
          <dd class="nl2br" v-text="$store.getters.shopInfo.about_me" />
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
export default {
  name: 'UserAbout',
  components: {
    HeaderInter
  },
  data() {
    return {}
  },
  created() {},
  mounted() {}
}
</script>

<style scoped lang="scss">
@import "assets/scss/common.scss";
.box-group {
  margin: 0 auto 1.5rem;
  padding: 15px 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);
  .box-items {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    margin-bottom: 1rem;
    padding: 0.5rem 0 0;
    border-bottom: 1px solid rgba($main-color, $alpha: 0.15);
    .box-th {
      flex: 0 0 30%;
      padding: 5px 15px 5px 0px;
      white-space: nowrap;
      text-align: right;
    }
    .box-td {
      text-align: left;
      padding-left: 10px;
    }
  }
}
.box-items:last-child {
  border-bottom: 0px solid rgba($main-color, $alpha: 0.15);
}
.main {
  p {
    font-size: 1rem;
    line-height: 1.85rem;
    margin: 0 0 0.5rem;
    padding: 0;
  }
  dl{
    padding-bottom: 1rem;
  }
  dt {
    color: $main-color;
    margin-bottom: 0.35rem;
  }
  dd {
    font-size: 1rem;
    line-height: 1.85rem;
    margin: 0;
    padding: 0;
  }
  dd span {
    color: $sec-color;
  }
}
</style>
